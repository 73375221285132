<template>
  <div>
    <div :class="$style.detail">
      <div :class="$style.img">
        <img :src="oss + '/manage/icon/front/miniprogram.png'" />
      </div>
      <p :class="$style.title">微信小程序授权</p>
      <p class="tip">授权会打开新的页面，请使用微信小程序管理员微信扫描授权二维码并选择对应的微信小程序。</p>
      <p class="tip">（请不要操作权限，以免功能使用异常）</p>
      <div>
        <BjButton :class="$style.btn" type="primary" :loading="loading" @click="getUrl()">
          <i class="ri-link left" />
          立即授权
        </BjButton>
      </div>
    </div>
  </div>
</template>

<script>
import { terminalManageService } from '@/service'

const service = new terminalManageService()

export default {
  name: 'wxAuthor',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async getUrl() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        this.loading = true
        const { data } = await service.getUrl({
          platform_type: 'mini_program',
        })
        this.loading = false
        this.$confirm({
          title: '授权确认',
          content: '请确认授权是否已经完成？',
          onOk() {
            _this.$router.push({
              name: 'terminalManage',
            })
          },
          okText: '已完成',
          cancelText: '未完成',
          onCancel() {
            _this.$router.push({
              name: 'terminalManage',
            })
          },
        })
        window.open(data.auth_url)
      } catch (e) {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="less" module>
.detail {
  height: calc(100vh - 152px);
  padding-top: 180px;
  text-align: center;
  background: #fff;

  p {
    margin-bottom: 10px;
  }

  .img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    overflow: hidden;

    img {
      max-width: 70px;
      max-height: 70px;
    }
  }

  .btn {
    width: 250px;
    height: 36px;
    margin-top: 30px;
  }

  .title {
    margin-top: 20px;
    color: #000;
    font-size: 20px;
  }
}
</style>
